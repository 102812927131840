<template>
  <div class="work content">

    <div class="lores s12">
      <p class="title lores s21">
        &gt; Work
      </p>
      <div class="toc">
        <a href="javascript:void(0)" class="flipped secondary" v-on:click="collapsed=!collapsed">directory (5)</a>
        <template v-if="!collapsed">
          <br>
          <a href="#sst"   >├─ Sundhedsstyrelsen</a><br>
          <a href="#audi"   >├─ Audi</a><br>
          <a href="#zalando">├─ Zalando</a><br>
          <a href="#plk"    >├─ Peter Larsen Kaffe</a><br>
          <a href="#mediano">├─ Mediano</a><br>
          <a href="#other"  >└─ Other (showing first 4 results)</a><br>
          <a href="#other"  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;├─ Heineken</a><br>
          <a href="#other"  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;├─ Just Eat</a><br>
          <a href="#other"  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;├─ Saxo</a><br>
          <a href="#other"  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;├─ HP Omen</a><br>
          <a href="#other"  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;└─ ...more</a><br>
        </template>
      </div>

        <div class="clients">

          <div class="client" id="audi">
            <p class="name lores s21">Audi</p>
            <p class="category">Video- &amp; Photography</p>
            <div class="image-gallery hoverable" v-dragscroll.x>
              <img v-for="img in audi" :key="img" :src="`${publicPath}img/work/audi/${img}`" alt="audi photo" class="hoverable">
              <a href="https://vimeo.com/452329677" target="_blank" class="full flipped tertiary"><span class="center">Full Video^</span></a>
            </div>
          </div>

          <div class="client" id="zalando">
            <p class="name lores s21">Zalando</p>
            <p class="category">Photography</p>
            <div class="image-gallery hoverable" v-dragscroll.x>
              <img v-for="img in zalando" :key="img" :src="`${publicPath}img/work/zalando/${img}`" alt="zalando photo" class="hoverable">
            </div>
          </div>

          <div class="client" id="sst">
            <p class="name lores s21">Sundhedsstyrelsen</p>
            <p class="category">Videography</p>
            <div class="image-gallery hoverable" v-dragscroll.x>
              <img v-for="img in sst" :key="img" :src="`${publicPath}img/work/sst/${img}`" alt="sundhedsstyrelsen photo" class="hoverable">
              <a href="https://vimeo.com/794562184" target="_blank" class="full flipped tertiary"><span class="center">Full Video^</span></a>
            </div>
          </div>

          <div class="client" id="plk">
            <p class="name lores s21">Peter Larsen Kaffe</p>
            <p class="category">Video- &amp; Photography</p>
            <div class="image-gallery hoverable" v-dragscroll.x>
              <img v-for="img in plk" :key="img" :src="`${publicPath}img/work/plk/${img}`" alt="peter larsen kaffe still from video" class="hoverable">
              <a href="https://vimeo.com/648050718" target="_blank" class="full flipped tertiary"><span class="center">Full Video^</span></a>
            </div>
          </div>

          <div class="client" id="mediano">
            <p class="name lores s21">Mediano</p>
            <p class="category">Everything (Design, Dev, Video- & Photography)</p>
            <div class="image-gallery hoverable" v-dragscroll.x>
              <img v-for="img in mediano" :key="img" :src="`${publicPath}img/work/mediano/${img}`" alt="mediano graphic" class="hoverable">
            </div>
          </div>

          <div class="client" id="other">
            <p class="name lores s21">And a few others ...</p>
            <p class="category">including but not limited to:</p>
            <p class="lores s21">
              Heineken, Just Eat, Saxo, HP Omen, Det Kongelige Teater, Ugebrevet A4, VisitNordsjælland, Miiego
            </p>
          </div>

        </div>

    </div>

  </div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'

export default {
  name: 'Work',
  directives: {
    dragscroll
  },
  props: {
    color: Object
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      collapsed: false,
      audi: [
        '_DSC0705-1.jpg',
        '_DSC0779-4.jpg',
        '_DSC0708-2.jpg',
        '_DSC0763-3.jpg',
        '_DSC0839-6.jpg',
        '_DSC0866-9.jpg',
        '_DSC0847-7.jpg'
      ],
      zalando: [
        '_DSC7972.jpg',
        '_DSC7909.jpg',
        '_DSC7937.jpg',
        '_DSC7850.jpg',
        //'_DSC7895.jpg',
        //'_DSC7890.jpg',
        '_DSC7959.jpg'
      ],
      sst: [
        'PETER1.jpg',
        'ADAM1.jpg',
        'GISLE1.jpg'
      ],
      mediano: [
        '_DSC0632.jpg',
        '_DSC0578.jpg',
        '_DSC0530.jpg',
        '_DSC0445.jpg',
        '_DSC0480.jpg',
        '_DSC0509.jpg',
        'LAF.jpg'
      ],
      plk: [
        'labels.jpg',
        'dan.jpg',
        'bike.jpg',
        'roaster.jpg',
        'title.jpg',
        'side.jpg',
        //'top.jpg',
        'draw.jpg',
        'sit.jpg'
      ],
      bruch: [
        'fable.png',
        //'logo1.png',
        'paroaria.png',
        'version11.png'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.client {
  margin-bottom: 2rem;
}

.client p {
  margin: 0;
}

.image-gallery, .vimeo {
  margin: 2rem 0;
  white-space: nowrap;
  overflow: auto;
}

.image-gallery img {
  display: inline-block;
  height: 520px;
  max-height: 50vw;
}

.image-gallery .full {
  display: inline-block;
  height: 520px;
  width: 520px;
  max-height: 50vw;
  max-width: 50vw;
  vertical-align: top;
  position: relative;
}

.image-gallery .full:after {
  content: "";
}

.image-gallery .full .center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-gallery *:not(:last-child) {
  margin-right: 2rem;
}
</style>
